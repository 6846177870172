<template>
  <div class="z-0">
    <header>
      <ConnectWallet />
    </header>
    <div
      class="flex flex-col items-center justify-start w-full text-yellow-50 p-3 mb-28"
    >
      <div class="py-1 max-w-5xl w-full flex-none">
        <div class="p-4">
          <div>
            <a
              v-on:click="$router.go(-1)"
              class="flex items-center space-x-2 text-center cursor-pointer"
              ><i class="fad fa-arrow-left mr-1"></i><span>Go Back</span></a
            >
          </div>
        </div>
        <div class="mb-3">
          <input
            type="text"
            class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-100 bg-gray-800 bg-clip-padding border-solid border-purple-300 border-2 rounded-full transition ease-in-out m-0 focus:text-gray-200 focus:bg-gray-900 focus:border-blue-300 focus:outline-none"
            placeholder="Search"
            v-model="search"
          />
        </div>
      </div>

      <div class="bg-gray-800 py-2 px-3 rounded max-w-5xl w-full flex-none">
        <div class="text-base font-medium">
          <span class="mr-4 font-bold">Transactions</span>
          <i class="fad fa-wallet mr-2 z-0"></i>
          <span
            class="text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-400"
          >
            KYC
          </span>
        </div>
      </div>

      <div class="flex-glow max-w-5xl w-full">
        <div>
          <div class="w-full items-center">
            <div v-for="t in dataKYC.dataKyc" :key="t">
              <div
                @click="showId(t)"
                class="flex w-full bg-gray-500 bg-opacity-10 my-1 hover:bg-gray-500 hover:bg-opacity-25 p-2 rounded-md"
              >
                <div class="flex-grow flex flex-col sm:flex-row">
                  <div class="sm:mr-2 text-gray-500 text-sm sm:text-base">
                    <div class="text-blue text-blue-400 mr-4 text-sm">
                      <i class="fad fa-link mr-1"></i>
                      {{ t.address }}
                    </div>
                  </div>

                  <div class="text-sm sm:text-base">
                    <span class="text-xs">Name: {{ t.name }} </span>
                  </div>
                </div>
                <div
                  class="flex-none text-right flex flex-col sm:flex-row text-sm sm:text-base"
                >
                  <h2
                    class="text-sm font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-green-700"
                    v-if="t.status == 'APPROVE'"
                  >
                    {{ t.status }}
                  </h2>
                  <h2
                    class="text-sm font-bold text-transparent bg-clip-text bg-gradient-to-r from-red-400 to-red-700"
                    v-if="t.status == 'REJECT'"
                  >
                    {{ t.status }}
                  </h2>
                  <h2
                    class="text-sm font-bold text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-yellow-700"
                    v-if="t.status == 'WAIT'"
                  >
                    {{ t.status }}
                  </h2>
                </div>
              </div>
              <div v-if="id == t._id">
                <div>
                  {{ t.name }}
                  <br />
                  {{ t.phone }}
                  <br />
                  {{ t.email }}

                  <div class="mt-5">
                    <button
                      @click="approve(t)"
                      class="flex-none text-sm px-6 py-2 border rounded text-yellow-50 border-none bg-gradient-to-r from-green-700 to-green-500"
                    >
                      APPROVE
                    </button>

                    <button
                      @click="reject(t)"
                      class="ml-3 flex-none text-sm px-6 py-2 border rounded text-yellow-50 border-none bg-gradient-to-r from-red-700 to-red-500"
                    >
                      REJECT
                    </button>
                  </div>

                  {{ t.remark }}

                  <div v-for="u in t.url" :key="u">
                    <img :src="API_URL + '/images/uploads/' + u" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <StickFooter />
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import chameleon from "@/assets/svg/chameleon.vue";
import loader from "@/assets/svg/loader.vue";
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/StickFooterAdmin.vue";
import info from "@/api/info";
import func from "@/widgets/func";
import label from "@/widgets/label";
import moment from "moment";
import nativeToast from "native-toast";

import kyc from "@/api/kyc";

import Swal from "sweetalert2";

import {
  POOL_ADDRESS,
  POOL_ADDRESS_ODD,
  VERSION,
  EXPLORER,
  API_URL,
} from "../../../config";

export default {
  components: {
    loader,
    chameleon,
    ConnectWallet,
    StickFooter,
  },
  data() {
    return {
      search: "",
      address: "hs",
      dataKYC: [],
      id: "",
      API_URL,
    };
  },
  computed: {
    ...mapGetters(["getCurrency"]),
  },

  methods: {
    fixed(a) {
      return func.fixed(a);
    },
    expand(id) {
      if (this.expanded == id) {
        this.expanded = null;
        return;
      }
      this.expanded = id;
    },
    getdata() {
      kyc
        .data()
        .then((res) => {
          this.dataKYC = res.data;
        })
        .catch((error) => {});
    },
    showId(t) {
      if (this.id == t._id) {
        this.id = "";
      } else {
        this.id = t._id;
      }
    },
    async approve(t) {
      Swal.fire({
        title: "Are you sure APPROVE?",
        text: "Pleas save",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, approve",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await kyc
            .updateKYC({
              address: t.address,
              status: "APPROVE",
            })
            .then(() => {
              this.id = "";

              this.getdata();

              nativeToast({
                message: "APPROVE SUCCESS",
                position: "top",
                timeout: 2000,
                type: "success",
              });
            })
            .catch((error) => {});
        }
      });
    },
    async reject(t) {
      Swal.fire({
        title: "Submit your remark.",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "SAVE",
        showLoaderOnConfirm: true,

        allowOutsideClick: () => !Swal.isLoading(),
      }).then(async (result) => {
        if (result.isConfirmed) {
          await kyc
            .updateKYC({
              address: t.address,
              status: "REJECT",
              remark: result.value,
            })
            .then(() => {
              this.id = "";

              this.getdata();

              nativeToast({
                message: "REJECT SUCCESS",
                position: "top",
                timeout: 2000,
                type: "success",
              });
            })
            .catch((error) => {});
        }
      });
    },
  },
  created() {
    this.getdata();
  },
};
</script>
